@import url('https://fonts.googleapis.com/css?family=Heebo:200,300,400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Oswald:200,300,400,500,600,700');

@font-face {
  font-family: 'Gloriola';
  src: url('../style/fonts/Gloriola_Regular.otf');
  font-style: normal;
}

@font-face {
  font-family: 'Gloriola';
  src: url('../style/fonts/Gloriola_Semibold.otf');
  font-weight: bold;
  font-style: normal;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
  background: lightslategray;
}

::-webkit-scrollbar-corner {
  background-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb:hover {
  background: #666;
}

:global {
  & .flexlayout__layout {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    overflow: hidden;
  }

  & .flexlayout__splitter {
    border-radius: 5px;
    z-index: 900;
  }

  & .flexlayout__splitter-dark:hover {
    background-color: var(--bars);
  }

  & .flexlayout__splitter_drag-dark {
    background-color: var(--hovered);
  }

  & .flexlayout__splitter-bm:hover {
    background-color: #00806c;
  }

  & .flexlayout__splitter_drag-bm {
    background-color: #00806c;
  }

  & .flexlayout__splitter-igf:hover {
    background-color: #00806c;
  }

  & .flexlayout__splitter_drag-igf {
    background-color: #00806c;
  }

  & .flexlayout__outline_rect {
    position: absolute;
    cursor: move;
    border-radius: 5px;
    z-index: 1000;
    box-sizing: border-box;
  }

  & .flexlayout__outline_rect-dark {
    box-shadow: inset 0 0 60px rgba(105, 104, 104, 0.521);
  }

  & .flexlayout__outline_rect-bm {
    border: 2px solid red;
    box-shadow: inset 0 0 60px rgba(0, 0, 0, 0.2);
  }

  & .flexlayout__outline_rect-igf {
    border: 2px solid red;
    box-shadow: inset 0 0 60px rgba(0, 0, 0, 0.2);
  }

  & .flexlayout__outline_rect_edge {
    cursor: move;
    box-shadow: inset 0 0 60px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    z-index: 1000;
    box-sizing: border-box;
  }

  & .flexlayout__outline_rect_edge-dark {
    border: 2px solid #b7d1b5;
  }

  & .flexlayout__outline_rect_edge-bm {
    border: 2px solid green;
  }

  & .flexlayout__outline_rect_edge-igf {
    border: 2px solid green;
  }

  & .flexlayout__edge_rect {
    position: absolute;
    z-index: 1000;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  }

  & .flexlayout__edge_rect-dark {
    background-color: whitesmoke;
  }

  & .flexlayout__edge_rect-bm {
    background-color: lightgray;
  }

  & .flexlayout__edge_rect-igf {
    background-color: lightgray;
  }

  & .flexlayout__drag_rect {
    position: absolute;
    cursor: move;
    border-radius: 0px;
    z-index: 1000;
    box-sizing: border-box;
    opacity: 1;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    padding: 20px;
    word-wrap: break-word;
    font-weight: bold;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  & .flexlayout__drag_rect-dark {
    background-color: whitesmoke;
  }

  & .flexlayout__drag_rect-bm {
    font-family: 'Gloriola', Arial, sans-serif;
    background-color: #eeeeee;
  }

  & .flexlayout__drag_rect-igf {
    font-family: 'Gloriola', Arial, sans-serif;
    background-color: #eeeeee;
  }

  & .flexlayout__tab {
    overflow: auto;
    position: absolute;
    box-sizing: border-box;
    color: white;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    scrollbar-color: rgba(255, 255, 255, 0.4) rgba(0, 0, 0, 0);
    scrollbar-width: thin;
  }

  & .flexlayout__tab-dark {
    scrollbar-color: rgba(255, 255, 255, 0.4) rgba(0, 0, 0, 0);
  }

  & .flexlayout__tab-bm {
    scrollbar-color: rgb(119, 136, 153) white;
  }

  & .flexlayout__tab-igf {
    scrollbar-color: rgb(119, 136, 153) white;
  }

  & .flexlayout__tab_button {
    color: var(--subtitle);
    cursor: pointer;
    padding: 12px 13px 5px 12px;
    margin: 0px 0px 0px 2px;
    float: left;
    vertical-align: top;
    box-sizing: border-box;
    font-size: 1rem;
    display: flex;
    flex-flow: nowrap;
  }

  & .flexlayout__tab_button-bm {
    border: 1px solid #eee;
  }

  & .flexlayout__tab_button-igf {
    border: 1px solid #eee;
  }

  & .flexlayout__tab_button-dark--selected {
    color: var(--white);
    margin-right: 1px;
  }

  & .flexlayout__tab_button-dark--unselected {
    color: gray;
    margin-right: 1px;
  }

  & .flexlayout__tab_button-bm--selected label {
    color: #323232;
    margin-right: 1px;
  }

  & .flexlayout__tab_button-bm--unselected label {
    color: #727272;
    margin-right: 1px;
  }

  & .flexlayout__tab_button-igf--selected label {
    color: #323232;
    margin-right: 1px;
  }

  & .flexlayout__tab_button-igf--unselected label {
    color: #727272;
    margin-right: 1px;
  }

  & .flexlayout__tab_button_leading {
    display: inline-block;
  }

  & .flexlayout__tab_button_content {
    margin-top: -13px;
    float: left;
    font-size: 1rem;
    display: flex;
  }

  & .flexlayout__tab_button_content-dark {
    font-family: 'Oswald', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  & .flexlayout__tab_button_content-bm {
    font-family: 'Gloriola', Arial, sans-serif;
    line-height: 1.6;
  }

  & .flexlayout__tab_button_content-igf {
    font-family: 'Gloriola', Arial, sans-serif;
    line-height: 1.6;
  }

  & .flexlayout__tab_button_content-bm svg {
    color: #00806c;
  }

  & .flexlayout__tab_button_content-igf svg {
    color: #00806c;
  }

  & .flexlayout__tab_button_textbox {
    float: left;
    border: none;
  }

  & .flexlayout__tab_button_textbox-dark {
    color: lightgreen;
    background-color: #222;
  }

  & .flexlayout__tab_button_textbox-bm {
    color: green;
    background-color: #ddd;
  }

  & .flexlayout__tab_button_textbox-igf {
    color: green;
    background-color: #ddd;
  }

  & .flexlayout__tab_button_textbox:focus {
    outline: none;
  }

  & .flexlayout__tab_button_trailing {
    float: left;
    display: inline-block;
    margin-left: 11px;
    width: 8px;
    height: 8px;
  }

  & .flexlayout__tab_button_trailing-bm {
    margin-top: -2px;
  }

  & .flexlayout__tab_button_trailing-igf {
    margin-top: -2px;
  }

  & .flexlayout__tab_button_trailing-dark:hover,
  & .flexlayout__tab_button--selected .flexlayout__tab_button_trailing-dark {
    background: transparent url('../img/Close.svg') no-repeat center;
  }

  & .flexlayout__tab_button_trailing-bm:hover,
  & .flexlayout__tab_button--selected .flexlayout__tab_button_trailing-bm {
    background: transparent url('../img/CloseBM.svg') no-repeat center;
  }

  & .flexlayout__tab_button_trailing-igf:hover,
  & .flexlayout__tab_button--selected .flexlayout__tab_button_trailing-igf {
    background: transparent url('../img/CloseIGF.svg') no-repeat center;
  }

  & .flexlayout__tab_button_overflow {
    float: left;
    width: 20px;
    height: 15px;
    margin-top: 2px;
    margin-left: 5px;
    padding-left: 12px;
    border: none;
    font-size: 10px;
    color: lightgray;
    font-family: Arial, sans-serif;
    background: transparent url('../img/more.png') no-repeat left;
  }

  & .flexlayout__tabset_header {
    position: absolute;
    left: 0;
    right: 0;
    color: #eee;
    height: 30px;
    background-color: red;
    padding: 3px 3px 3px 5px;
    box-sizing: border-box;
  }

  & .flexlayout__tab_header_inner {
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    white-space: nowrap;
  }

  & .flexlayout__tab_header_inner-bm div {
    background-color: transparent;
  }

  & .flexlayout__tab_header_inner-igf div {
    background-color: transparent;
  }

  & .flexlayout__tab_header_outer {
    display: flex;
    justify-content: flex-start;
  }

  & .flexlayout__tabset-maximized-dark {
    background-image: linear-gradient(#666, #333);
  }

  & .flexlayout__tabset-maximized-bm {
    background-image: linear-gradient(#aaa, #eee);
  }

  & .flexlayout__tabset-maximized-igf {
    background-image: linear-gradient(#aaa, #eee);
  }

  & .flexlayout__tab_toolbar {
    margin-left: 1px;
    display: flex;
    justify-content: flex-start;
  }

  & .flexlayout__tab_toolbar-bm {
    border: 1px solid #eee;
  }

  & .flexlayout__tab_toolbar-igf {
    border: 1px solid #eee;
  }

  & .flexlayout__tab_toolbar_button-min {
    width: 20px;
    height: 20px;
    border: none;
    outline-width: 0;
    background: transparent url('../img/maximize.png') no-repeat center;
  }

  & .flexlayout__tab_toolbar_button-max {
    width: 20px;
    height: 20px;
    border: none;
    outline-width: 0;
    background: transparent url('../img/restore.png') no-repeat center;
  }

  & .flexlayout__popup_menu_item {
    padding: 2px 10px 2px 10px;
  }

  & .flexlayout__popup_menu_item-dark {
    color: #ddd;
  }

  & .flexlayout__popup_menu_item-dark:hover {
    background-color: #444444;
  }

  & .flexlayout__popup_menu_item-bm:hover {
    background-color: lightgray;
  }

  & .flexlayout__popup_menu_item-igf:hover {
    background-color: lightgray;
  }

  & .flexlayout__popup_menu_container {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    position: absolute;
    z-index: 1000;
  }

  & .flexlayout__popup_menu_container-dark {
    border: 1px solid #555;
    background: #222;
  }

  & .flexlayout__popup_menu_container-bm {
    border: 1px solid lightgrey;
    background: white;
  }

  & .flexlayout__popup_menu_container-igf {
    border: 1px solid lightgrey;
    background: white;
  }

  & .flexlayout__border_top {
    border-bottom: 1px solid #ddd;
    box-sizing: border-box;
    overflow: hidden;
  }

  & .flexlayout__border_top-dark {
    background-color: black;
  }

  & .flexlayout__border_top-bm {
    background-color: #eeeeee;
  }

  & .flexlayout__border_top-igf {
    background-color: #eeeeee;
  }

  & .flexlayout__border_bottom {
    box-sizing: border-box;
    overflow: hidden;
  }

  & .flexlayout__border_bottom-dark {
    background-color: black;
    border-top: 1px solid #333;
  }

  & .flexlayout__border_bottom-bm {
    background-color: #eeeeee;
    border-top: 1px solid #ddd;
  }

  & .flexlayout__border_bottom-igf {
    background-color: #eeeeee;
    border-top: 1px solid #ddd;
  }

  & .flexlayout__border_left {
    box-sizing: border-box;
    overflow: hidden;
  }

  & .flexlayout__border_left-dark {
    background-color: black;
    border-right: 1px solid #333;
  }

  & .flexlayout__border_left-bm {
    background-color: #eeeeee;
    border-right: 1px solid #ddd;
  }

  & .flexlayout__border_left-igf {
    background-color: #eeeeee;
    border-right: 1px solid #ddd;
  }

  & .flexlayout__border_right {
    box-sizing: border-box;
    overflow: hidden;
  }

  & .flexlayout__border_right-dark {
    background-color: black;
    border-left: 1px solid #333;
  }

  & .flexlayout__border_right-bm {
    background-color: #eeeeee;
    border-left: 1px solid #ddd;
  }

  & .flexlayout__border_right-igf {
    background-color: #eeeeee;
    border-left: 1px solid #ddd;
  }

  & .flexlayout__border_inner_bottom {
    display: flex;
  }

  & .flexlayout__border_inner_left {
    position: absolute;
    white-space: nowrap;
    right: 23px;
    transform-origin: top right;
    transform: rotate(-90deg);
  }

  & .flexlayout__border_inner_right {
    position: absolute;
    white-space: nowrap;
    left: 23px;
    transform-origin: top left;
    transform: rotate(90deg);
  }

  & .flexlayout__border_button {
    display: inline-block;
    cursor: pointer;
    padding: 2px 8px 3px 8px;
    margin: 2px;
    border-radius: 3px;
    vertical-align: top;
    box-sizing: border-box;
    white-space: nowrap;
  }

  & .flexlayout__border_button-dark {
    background-color: #222;
    color: white;
  }

  & .flexlayout__border_button-bm {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.15);
  }

  & .flexlayout__border_button-igf {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.15);
  }

  & .flexlayout__border_button-dark--selected {
    color: #ddd;
  }

  & .flexlayout__border_button-bm--selected {
    color: black;
    background-color: #ddd;
  }

  & .flexlayout__border_button-igf--selected {
    color: black;
    background-color: #ddd;
  }

  & .flexlayout__border_button--unselected {
    color: gray;
  }

  & .flexlayout__border_button_leading {
    display: inline;
  }

  & .flexlayout__border_button_leading-bm {
    float: left;
  }

  & .flexlayout__border_button_leading-igf {
    float: left;
  }

  & .flexlayout__border_button_content {
    display: inline-block;
  }

  & .flexlayout__border_button_textbox {
    float: left;
    border: none;
    color: green;
    background-color: #ddd;
  }

  & .flexlayout__border_button_textbox:focus {
    outline: none;
  }

  & .flexlayout__border_button_trailing {
    display: inline-block;
    margin-left: 5px;
    margin-top: 3px;
    width: 8px;
    height: 8px;
  }

  & .flexlayout__border_button-dark:hover .flexlayout__border_button_trailing-dark,
  & .flexlayout__border_button-dark--selected .flexlayout__border_button_trailing {
    background: transparent url('../img/Close.svg') no-repeat center;
  }

  & .flexlayout__border_button-bm:hover .flexlayout__border_button_trailing-bm,
  .flexlayout__border_button-bm--selected .flexlayout__border_button_trailing {
    background: transparent url('../img/close.png') no-repeat center;
  }

  & .flexlayout__border_button-igf:hover .flexlayout__border_button_trailing-igf,
  .flexlayout__border_button-igf--selected .flexlayout__border_button_trailing {
    background: transparent url('../img/close.png') no-repeat center;
  }

  & .flexlayout__border_toolbar_left {
    position: absolute;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    bottom: 0;
    left: 0;
    right: 0;
  }

  & .flexlayout__border_toolbar_right {
    position: absolute;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    bottom: 0;
    left: 0;
    right: 0;
  }

  & .flexlayout__border_toolbar_top {
    position: absolute;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    top: 0;
    bottom: 0;
    right: 0;
  }

  & .flexlayout__border_toolbar_bottom {
    position: absolute;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    top: 0;
    bottom: 0;
    right: 0;
  }
}
